@use 'mixins' as *;

.hero-block {
  @include portrait {
    aspect-ratio: 3/4;
  }

  @include landscape {
    aspect-ratio: 12/5;
  }
}
