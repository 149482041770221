.iframe-container {
  overflow: auto;
  block-size: var(--height, 500px);
  inline-size: var(--width, 100%);

  iframe {
    border: 0;
    block-size: 100%;
    inline-size: 100%;
  }
}
