.image-slider {
  position: relative;
  scroll-margin: var(--scroll-margin);
  margin-inline: auto;

  &.small {
    max-inline-size: 62.5rem;
  }

  :global(.swiper) {
    inline-size: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xsmall);
  }

  :global(.swiper-slide) {
    position: relative;

    img {
      object-fit: contain;
    }
  }
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-xsmall);
}

.navigation-button {
  --button-size: 3rem;
  cursor: pointer;
  height: var(--button-size);
  width: var(--button-size);
  display: flex;
  align-items: center;
  justify-content: center;
  &.flip {
    svg {
      transform: rotate(180deg);
    }
  }
}
