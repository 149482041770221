@use 'sass:map';

$-breakpoints: (
  'tiny': 420px,
  'small': 768px,
  'medium': 1024px,
  'large': 1200px,
  'max': 1920px,
) !default;
$breakpoint-tiny: map.get(
  $map: $-breakpoints,
  $key: 'tiny',
);
$breakpoint-small: map.get(
  $map: $-breakpoints,
  $key: 'small',
);
$breakpoint-medium: map.get(
  $map: $-breakpoints,
  $key: 'medium',
);
$breakpoint-large: map.get(
  $map: $-breakpoints,
  $key: 'large',
);
$breakpoint-max: map.get(
  $map: $-breakpoints,
  $key: 'max',
);

// Apply styles above given breakpoint
@mixin breakpoint-min($size, $extra: false) {
  $breakpoint: map.get(
    $map: $-breakpoints,
    $key: $size,
  );

  @if $extra {
    @media screen and (width >= $breakpoint) and ($extra) {
      @content;
    }
  } @else {
    @media screen and (width >= $breakpoint) {
      @content;
    }
  }
}

// Apply styles below given breakpoint

@mixin breakpoint-max($size, $extra: false) {
  $breakpoint: map.get(
    $map: $-breakpoints,
    $key: $size,
  );

  @if $extra {
    @media screen and (width < $breakpoint) and ($extra) {
      @content;
    }
  } @else {
    @media screen and (width < $breakpoint) {
      @content;
    }
  }
}

// Apply styles between given breakpoints

@mixin breakpoint-min-max($min, $max, $extra: false) {
  $breakpoint1: map.get(
    $map: $-breakpoints,
    $key: $min,
  );
  $breakpoint2: map.get(
    $map: $-breakpoints,
    $key: $max,
  );

  @if $extra {
    @media screen and (width >= $breakpoint1) and (width < $breakpoint2) and ($extra) {
      @content;
    }
  } @else {
    @media screen and (width >= $breakpoint1) and (width < $breakpoint2) {
      @content;
    }
  }
}

// Apply styles if user prefers reduced motion
@mixin reduced-motion($preference: reduce) {
  @media screen and (prefers-reduced-motion: $preference) {
    @content;
  }
}

// Apply styles if user prefers increased contrast
@mixin contrast($preference: more) {
  @media screen and (prefers-contrast: $preference) {
    @content;
  }
}

// Orientation
@mixin orientation($orientation: portrait) {
  @media screen and (orientation: $orientation) {
    @content;
  }
}

@mixin portrait {
  @media screen and (orientation: portrait) {
    @content;
  }
}

@mixin landscape {
  @media screen and (orientation: landscape) {
    @content;
  }
}

@mixin touch {
  @media screen and (pointer: coarse) {
    @content;
  }
}

@mixin not-touch {
  @media screen and not (pointer: coarse) {
    @content;
  }
}

@mixin hover {
  @media screen and (hover: hover) {
    &:hover {
      @content;
    }
  }
}
