@use 'mixins' as *;

.product {
  position: relative;
  margin-block-end: var(--spacing-medium);

  &--is-small {
    margin-block-end: var(--spacing-regular);
  }

  &--in-favorites {
    margin-block-end: var(--spacing-medium);
  }
}

.product-wrapper {
  display: flex;
  flex-direction: column;
  inline-size: 100%;
}

.product-image {
  block-size: 100%;
  position: relative;
  background-color: var(--color-gray6);
}

.product-name {
  display: block;
  color: var(--color-black);
  transition: 0.2s ease;
  font-size: var(--font-size-body16);
  line-height: var(--line-height-body16);
  font-weight: var(--font-weight-regular);

  a {
    color: var(--color-black);
  }
}

.colors-badge-wrapper {
  display: flex;
  flex-direction: column;
  place-items: center;
  gap: var(--spacing-tiny);
  position: absolute;
  inset-inline-end: var(--spacing-small);
  inset-block-start: var(--spacing-small);
  list-style: none;

  &:empty {
    display: none;
  }

  svg {
    color: var(--color-gray0);
  }
}

.colors-badge {
  border-radius: 100%;
}

.product-icon-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  inset-inline-end: 0;
  inset-block-end: 0;
  z-index: 2;

  &:empty {
    display: none;
  }
}

.product-icon {
  inline-size: var(--spacing-regular);
  block-size: var(--spacing-regular);
  margin-inline-end: var(--spacing-small);
  margin-block-end: var(--spacing-small);
}

.product-info {
  padding: var(--spacing-xsmall) 0 0 var(--spacing-small);
  position: relative;
}

.product-price {
  margin-inline: 0;
  margin-block: var(--spacing-xsmall) var(--spacing-small);
  transition: 0.2s ease;
  display: flex;
  align-items: baseline;

  .rrp {
    color: var(--color-gray0);
    margin-inline-start: var(--spacing-xsmall);
  }

  p {
    margin: 0;
  }
}

.usp {
  ul {
    padding: 0;
    margin: 0;
    margin-block-start: 12px;
    list-style: initial;
    padding-inline-start: 1em;

    li {
      font-size: var(--font-size-body14);
      line-height: var(--line-height-body12);
      font-weight: var(--font-weight-regular);
      font-family: var(--font-regular);
      color: var(--color-black);
    }
  }
}
