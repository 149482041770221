.content-area {
  display: flex;
  flex-direction: column;
  &:empty {
    display: none;
  }
  &.large-margin {
    padding-block-end: 96px;
  }
}
