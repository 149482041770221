.footer {
  --footer-bg-color: var(--color-gray4, #eaeaea);
  --footer-font-family-heading: var(--font-hero);
  --footer-font-family-regular: var(--font-regular, --font-family-regular);
  --footer-font-weight-bold: var(--font-weight-medium, 500);
  --footer-font-weight-regular: var(--font-weight-regular, 400);
  --footer-heading-font-size: var(--font-size-header4, 1.25rem);
  --footer-heading-line-height: var(--line-height-header4, 26px);
  --footer-logo-color: var(--color-black, #111);
  --footer-logo-size: var(--spacing-medium, 48px);
  --footer-spacing-l: var(--spacing-medium, 48px);
  --footer-spacing-small: var(--spacing-small, 16px);
  --footer-text-color-hover: var(--color-hell-blue, #0000ff);
  --footer-text-color: var(--color-black, #111);
}

.logo {
  position: relative;
  height: 21px;
  width: 34px;
}
