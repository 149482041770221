@use 'mixins' as *;

.list {
  display: flex;
  list-style: none;
  padding: 0;
  gap: 12px;
  margin-block-end: var(--spacing-medium);
}

.list-item {
  position: relative;
  block-size: 40px;
  inline-size: 40px;

  @include breakpoint-min(small) {
    block-size: 32px;
    inline-size: 32px;
  }
}

.color {
  border-radius: 100%;
  padding: 3px;

  @include breakpoint-min(small) {
    padding: 2px;
  }

  &--active {
    border: 1px solid var(--color-gray2);
  }
}
